const heading = {
  display: `block`,
  color: `heading`,
  fontWeight: `heading`,
  textDecoration: `none`,
  mb: 3
}

const text = {
  heading: {
    ...heading,
    // dev mode 時只有修改上面 heading object 可即時生效, 修改下面這行無效, 必須 clean 後重新啟動才會生效
    // color: `headingRed`,
  },
  headingRed: {
    ...heading,
    color: `headingRed`,
  },
  h1: {
    ...heading,
    fontSize: [5, 6, null, 7],
    fontWeight: `bolder`
  },
  h2: {
    ...heading,
    fontSize: 4
  },
  h3: {
    ...heading,
    fontSize: 3
  },
  h4: {
    ...heading,
    fontSize: 2,
    fontWeight: `medium`
  },
  h5: {
    ...heading,
    fontSize: 1,
    fontWeight: `medium`
  },
  medium: {
    fontSize: 3,
    mb: 3
  },
  small: {
    fontSize: 1,
    m: 0
  },
  xsmall: {
    fontSize: 0,
    m: 0
  },
  p: {
    fontFamily: `body`,
    mb: 3
  }
}

export default text
