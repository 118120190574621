import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const Test = ({ ext }) => {
  const data = useStaticQuery(graphql`
    query {
      allJsonx {
        edges {
          node {
            id
            code
            name
            population
            size
          }
        }
      }
    }
  `)

  // console.log('ext=', ext, ', data=', data)
  const filterData = data.allJsonx.edges.filter(
    function (nodeContainer, index, arrSelf) {
      // console.log('node', nodeContainer.node)
      // console.log('node.extension=', nodeContainer.node.extension, ', ext=', ext)
      return nodeContainer.node.size > 0
    }
  )

  // console.log('ext=', ext, ', filterData=', filterData)

  return filterData
}

export default Test
