import * as React from 'react'
// import { makeStyles } from "@mui/material/styles";
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination, {
  tablePaginationClasses
} from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'

// Header border: 216 169 70
// 221 221 221
// 220 213 195
// 188 191 190
// Background
// 255 246 224
// Fore
// 60 62 61

// https://stackoverflow.com/questions/69624210/how-can-i-use-usetheme-in-material-ui-5
import { ThemeProvider, useThemeUI } from 'theme-ui'
import {
  createTheme as materialCreateTheme,
  ThemeProvider as MaterialThemeProvider,
  useTheme as materialUseTheme,
  THEME_ID
} from '@mui/material/styles'
import { zhTW } from '@mui/material/locale'

// const useStyles = makeStyles({
//   table: {
//     minWidth: 650
//   },
//   sticky: {
//     position: "sticky",
//     left: 0,
//     // background: "white",
//     // boxShadow: "5px 2px 5px grey",
//     // borderRight: "2px solid black"
//   }
// });

function RedGreenTextColor(value: number): string {
  return value > 0 ? 'red' : value < 0 ? 'green' : 'black'
}

function IntFormat(value: number): string {
  return value.toLocaleString('en-US', {
    style: 'decimal',
    maximumFractionDigits: 0
  })
}

function NumberFormat(value: number): string {
  return value.toLocaleString('en-US', {
    style: 'decimal',
    // signDisplay: 'exceptZero',
    maximumFractionDigits: 2
  })
}

function SignedNumberFormat(value: number): string {
  return value.toLocaleString('en-US', {
    style: 'decimal',
    signDisplay: 'exceptZero',
    maximumFractionDigits: 2
  })
}

function SignedPercentFormat(value: number): string {
  return value.toLocaleString('en-US', {
    style: 'percent',
    signDisplay: 'exceptZero',
    maximumFractionDigits: 2
  })
}

interface Column {
  id:
    | 'rank'
    | 'stockId'
    | 'name'
    | 'netbuy'
    | 'netbuyDollar'
    | 'close'
    | 'change'
    | 'changeRatio'
    | 'netbuyDays'
    | 'netbuy5'
    | 'changeRatio5'
    | 'netbuy10'
    | 'changeRatio10'
    | 'netbuy20'
    | 'changeRatio20'
    | 'netbuy60'
    | 'changeRatio60'
    | 'netbuyYtd'
    | 'changeRatioYtd'
  sticky: boolean
  stickyIndex?: number
  label: string
  minWidth?: number
  align: 'right' | 'left' | 'center'
  format?: (value: number) => string
  textColor?: (value: number) => string
}

const columns: readonly Column[] = [
  {
    id: 'rank',
    label: '名次',
    minWidth: 50,
    align: 'center',
    sticky: true,
    stickyIndex: 0
  },
  {
    id: 'stockId',
    label: '代號',
    minWidth: 30,
    align: 'left',
    sticky: true,
    stickyIndex: 50
  },
  {
    id: 'name',
    label: '名稱',
    minWidth: 200,
    align: 'left',
    sticky: true,
    stickyIndex: 130
  },
  {
    id: 'netbuy',
    label: '買超張數',
    minWidth: 100,
    align: 'right',
    format: IntFormat,
    sticky: false
  },
  {
    id: 'netbuyDollar',
    label: '買超金額 （億）',
    minWidth: 10,
    align: 'right',
    // format: (value: number) => value.toFixed(2),
    format: NumberFormat,
    sticky: false
  },
  {
    id: 'close',
    label: '收盤',
    minWidth: 30,
    align: 'right',
    format: NumberFormat,
    sticky: false
  },
  {
    id: 'change',
    label: '漲跌',
    minWidth: 30,
    align: 'right',
    // format: (value: number) =>
    //   value.toLocaleString('en-US', {
    //     style: 'decimal',
    //     signDisplay: 'exceptZero',
    //     maximumFractionDigits: 2
    //   }),
    format: SignedNumberFormat,
    // textColor: (value: number) =>
    //   value > 0 ? 'red' : value < 0 ? 'green' : 'black',
    textColor: RedGreenTextColor,
    sticky: false
  },
  {
    id: 'changeRatio',
    label: '漲幅',
    minWidth: 30,
    align: 'right',
    // format: (value: number) =>
    //   value.toLocaleString('en-US', {
    //     style: 'percent',
    //     signDisplay: 'exceptZero',
    //     maximumFractionDigits: 2
    //   }),
    format: SignedPercentFormat,
    textColor: RedGreenTextColor,
    sticky: false
  },
  {
    id: 'netbuyDays',
    label: '連買天數',
    minWidth: 100,
    align: 'center',
    sticky: false
  },
  {
    id: 'netbuy5',
    label: '５日平均買超張數',
    minWidth: 80,
    align: 'right',
    format: IntFormat,
    sticky: false
  },
  {
    id: 'changeRatio5',
    label: '５日漲幅',
    minWidth: 50,
    align: 'right',
    format: SignedPercentFormat,
    textColor: RedGreenTextColor,
    sticky: false
  },
  {
    id: 'netbuy10',
    label: '１０日平均買超張數',
    minWidth: 80,
    align: 'right',
    format: IntFormat,
    sticky: false
  },
  {
    id: 'changeRatio10',
    label: '１０日漲幅',
    minWidth: 50,
    align: 'right',
    format: SignedPercentFormat,
    textColor: RedGreenTextColor,
    sticky: false
  },
  {
    id: 'netbuy20',
    label: '２０日平均買超張數',
    minWidth: 80,
    align: 'right',
    format: IntFormat,
    sticky: false
  },
  {
    id: 'changeRatio20',
    label: '２０日漲幅',
    minWidth: 50,
    align: 'right',
    format: SignedPercentFormat,
    textColor: RedGreenTextColor,
    sticky: false
  },
  {
    id: 'netbuy60',
    label: '６０日平均買超張數',
    minWidth: 80,
    align: 'right',
    format: IntFormat,
    sticky: false
  },
  {
    id: 'changeRatio60',
    label: '６０日漲幅',
    minWidth: 50,
    align: 'right',
    format: SignedPercentFormat,
    textColor: RedGreenTextColor,
    sticky: false
  },
  {
    id: 'netbuyYtd',
    label: '今年以來平均買超張數',
    minWidth: 80,
    align: 'right',
    format: IntFormat,
    sticky: false
  },
  {
    id: 'changeRatioYtd',
    label: '今年以來漲幅',
    minWidth: 50,
    align: 'right',
    format: SignedPercentFormat,
    textColor: RedGreenTextColor,
    sticky: false
  }
]

interface Data {
  rank: number
  stockId: string
  name: string
  netbuy: number
  netbuyDollar: number
  close: number
  change: number
  changeRatio: number
  netbuyDays: string
  netbuy5: number
  changeRatio5: number
  netbuy10: number
  changeRatio10: number
  netbuy20: number
  changeRatio20: number
  netbuy60: number
  changeRatio60: number
  netbuyYtd: number
  changeRatioYtd: number
}

function createData(
  rank: number,
  stockId: string,
  name: string,
  netbuy: number,
  netbuyDollar: number,
  close: number,
  change: number,
  changeRatio: number,
  netbuyDays: string,
  netbuy5: number,
  changeRatio5: number,
  netbuy10: number,
  changeRatio10: number,
  netbuy20: number,
  changeRatio20: number,
  netbuy60: number,
  changeRatio60: number,
  netbuyYtd: number,
  changeRatioYtd: number
): Data {
  return {
    rank,
    stockId,
    name,
    netbuy,
    netbuyDollar,
    close,
    change,
    changeRatio,
    netbuyDays,
    netbuy5,
    changeRatio5,
    netbuy10,
    changeRatio10,
    netbuy20,
    changeRatio20,
    netbuy60,
    changeRatio60,
    netbuyYtd,
    changeRatioYtd
  }
}

export default function StickyHeadTable({ testData, test2 }) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // https://www.geeksforgeeks.org/react-mui-localization/
  const [locale, setLocale] = React.useState(zhTW)
  const materialTheme = materialCreateTheme(
    {
      palette: {
        mode: 'dark'
      },
      components: {
        // Name of the component
        // MuiButtonBase: {
        //   defaultProps: {
        //     // The props to change the default for.
        //     disableRipple: true // No more ripple, on the whole application 💣!
        //   }
        // },
        // MuiTableHead: {
        //   defaultProps: {},
        //   styleOverrides: {}
        // },
        // MuiTablePagination: {
        //   defaultProps: {},
        //   styleOverrides: {
        //     selectLabel: { height: '100%' }
        //   }
        // }
      }
    },
    zhTW
  )
  const themeWithLocale = React.useMemo(
    () => materialTheme,
    [materialTheme, locale]
  )
  console.log('DataTable5 materialTheme=', materialTheme)
  console.log('DataTable5 themeWithLocale=', themeWithLocale)

  console.log('testData=', testData)
  const newRows = testData.items.map(item =>
    createData(
      item.rank,
      item.stockId,
      item.name,
      item.netbuy,
      item.netbuyDollar,
      item.close,
      item.change,
      item.changeRatio,
      item.netbuyDays,
      item.netbuy5,
      item.changeRatio5,
      item.netbuy10,
      item.changeRatio10,
      item.netbuy20,
      item.changeRatio20,
      item.netbuy60,
      item.changeRatio60,
      item.netbuyYtd,
      item.changeRatioYtd
    )
  )

  console.log('newRows=', newRows)

  // const classes = useStyles();

  const dynamicRows = newRows

  return (
    <ThemeProvider theme={themeWithLocale}>
      <Paper sx={{ width: '100%', overflow: 'auto' }}>
        <TableContainer sx={{ maxHeight: 1600, maxWidth: 1600 }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead style={{ backgroundColor: 'red', color: 'white' }}>
              <TableRow>
                {columns.map(column => {
                  // console.log('header column=', column)
                  // align={column.align}
                  return (
                    <TableCell
                      key={column.id}
                      align='center'
                      style={{
                        minWidth: column.minWidth,
                        color: 'black',
                        ...(column.sticky
                          ? {
                              position: 'sticky',
                              left: column.stickyIndex,
                              zIndex: 999
                            }
                          : {}),
                        backgroundColor: 'rgba(249, 193, 77, 1.0)'
                      }}
                    >
                      {column.label}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {dynamicRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map(column => {
                        const value = row[column.id]
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              ...(column.textColor
                                ? { color: column.textColor(value) }
                                : { color: 'black' }),
                              border: '1px dotted rgb(221, 221, 221)',
                              ...(column.sticky
                                ? {
                                    position: 'sticky',
                                    left: column.stickyIndex,
                                    zIndex: 998
                                  }
                                : {}),
                              ...(index % 2 == 0
                                ? {
                                    backgroundColor: 'rgba(255, 255, 255, 1.0)'
                                  }
                                : {
                                    backgroundColor: 'rgba(255, 246, 224, 1.0)'
                                  })
                            }}
                          >
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component='div'
          count={dynamicRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            [`& .${tablePaginationClasses.root}`]: {
              display: 'flex',
              alignItems: 'center'
            },
            [`& .${tablePaginationClasses.spacer}`]: {
              display: 'none'
            },
            [`& .${tablePaginationClasses.toolbar}`]: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              verticalAlign: 'middle',
              textAlign: 'center'
            },
            [`& .${tablePaginationClasses.selectLabel}`]: {
              verticalAlign: 'middle',
              //   fontSize: '1.5rem',
              textAlign: 'center',
              marginTop: '0',
              marginBottom: '0'
            },
            [`& .${tablePaginationClasses.selectIcon}`]: {
              color: 'black',
              paddingLeft: '2px'
            },
            [`& .${tablePaginationClasses.displayedRows}`]: {
              verticalAlign: 'middle',
              //   fontSize: '1.2rem',
              textAlign: 'right',
              marginTop: '0',
              marginBottom: '0'
            }
          }}
          style={{
            // backgroundColor: 'rgba(249, 193, 77, 1.0)',
            backgroundColor: 'Wheat',
            color: 'black'
          }}
          labelRowsPerPage='每頁數量：'
          labelDisplayedRows={({ from, to, count }) =>
            `第 ${from} ～ ${to} ／ ${count === -1 ? `超過 ${to}` : `共 ${count} 筆`}`
          }
        />
      </Paper>
    </ThemeProvider>
  )
}
