import * as React from 'react'
// import { makeStyles } from "@mui/material/styles";
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination, {
  tablePaginationClasses
} from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'

// Header border: 216 169 70
// 221 221 221
// 220 213 195
// 188 191 190
// Background
// 255 246 224
// Fore
// 60 62 61

// https://stackoverflow.com/questions/69624210/how-can-i-use-usetheme-in-material-ui-5
import { ThemeProvider, useThemeUI } from 'theme-ui'
import {
  createTheme as materialCreateTheme,
  ThemeProvider as MaterialThemeProvider,
  useTheme as materialUseTheme,
  THEME_ID
} from '@mui/material/styles'
import { zhTW } from '@mui/material/locale'

// const useStyles = makeStyles({
//   table: {
//     minWidth: 650
//   },
//   sticky: {
//     position: "sticky",
//     left: 0,
//     // background: "white",
//     // boxShadow: "5px 2px 5px grey",
//     // borderRight: "2px solid black"
//   }
// });

interface Column {
  id: 'name' | 'code' | 'population' | 'size' | 'density'
  sticky: boolean
  label: string
  minWidth?: number
  align: 'right' | 'left' | 'center'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'name', label: 'Name', minWidth: 170, align: 'left', sticky: true },
  { id: 'code', label: 'ISO\u00a0Code', minWidth: 100, align: 'center', sticky: false },
  {
    id: 'population',
    label: 'Population',
    minWidth: 170,
    align: 'right',
    format: (value: number) => value.toLocaleString('en-US'),
    sticky: false
  },
  {
    id: 'size',
    label: 'Size\u00a0(km\u00b2)',
    minWidth: 170,
    align: 'right',
    format: (value: number) => value.toLocaleString('en-US'),
    sticky: false
  },
  {
    id: 'density',
    label: 'Density',
    minWidth: 170,
    align: 'right',
    format: (value: number) => value.toFixed(2),
    sticky: false
  }
]

interface Data {
  name: string
  code: string
  population: number
  size: number
  density: number
}

function createData(
  name: string,
  code: string,
  population: number,
  size: number
): Data {
  const density = population / size
  return { name, code, population, size, density }
}

const rawData = [
  {
    name: 'India',
    code: 'IN',
    population: 1324171354,
    size: 3287263
  },
  {
    name: 'China', code: 'CN',
    population: 1403500365,
    size: 9596961
  },
  {
    name: 'Italy', code: 'IT',
    population: 60483973,
    size: 301340
  },
  {
    name: 'United States', code: 'US',
    population: 327167434,
    size: 9833520
  },
  {
    name: 'Canada', code: 'CA',
    population: 37602103,
    size: 9984670
  },
  {
    name: 'Australia', code: 'AU',
    population: 25475400,
    size: 7692024
  },
  {
    name: 'Germany', code: 'DE',
    population: 83019200,
    size: 357578
  },
  {
    name: 'Ireland', code: 'IE',
    population: 4857000,
    size: 70273
  },
  {
    name: 'Mexico', code: 'MX',
    population: 126577691,
    size: 1972550
  },
  {
    name: 'Japan', code: 'JP',
    population: 126317000,
    size: 377973
  },
  {
    name: 'France', code: 'FR',
    population: 67022000,
    size: 640679
  },
  {
    name: 'United Kingdom', code: 'GB',
    population: 67545757,
    size: 242495
  },
  {
    name: 'Russia', code: 'RU',
    population: 146793744,
    size: 17098246
  },
  {
    name: 'Nigeria', code: 'NG',
    population: 200962417,
    size: 923768
  },
  {
    name: 'Brazil', code: 'BR',
    population: 210147125,
    size: 8515767
  },
]

const rows = [
  createData('India', 'IN', 1324171354, 3287263),
  createData('China', 'CN', 1403500365, 9596961),
  createData('Italy', 'IT', 60483973, 301340),
  createData('United States', 'US', 327167434, 9833520),
  createData('Canada', 'CA', 37602103, 9984670),
  createData('Australia', 'AU', 25475400, 7692024),
  createData('Germany', 'DE', 83019200, 357578),
  createData('Ireland', 'IE', 4857000, 70273),
  createData('Mexico', 'MX', 126577691, 1972550),
  createData('Japan', 'JP', 126317000, 377973),
  createData('France', 'FR', 67022000, 640679),
  createData('United Kingdom', 'GB', 67545757, 242495),
  createData('Russia', 'RU', 146793744, 17098246),
  createData('Nigeria', 'NG', 200962417, 923768),
  createData('Brazil', 'BR', 210147125, 8515767)
]

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // https://www.geeksforgeeks.org/react-mui-localization/
  const [locale, setLocale] = React.useState(zhTW)
  const materialTheme = materialCreateTheme(
    {
      palette: {
        mode: 'dark'
      },
      components: {
        // Name of the component
        // MuiButtonBase: {
        //   defaultProps: {
        //     // The props to change the default for.
        //     disableRipple: true // No more ripple, on the whole application 💣!
        //   }
        // },
        // MuiTableHead: {
        //   defaultProps: {},
        //   styleOverrides: {}
        // },
        // MuiTablePagination: {
        //   defaultProps: {},
        //   styleOverrides: {
        //     selectLabel: { height: '100%' }
        //   }
        // }
      }
    },
    zhTW
  )
  const themeWithLocale = React.useMemo(
    () => materialTheme,
    [materialTheme, locale]
  )
  console.log('DataTable3 materialTheme=', materialTheme)
  console.log('DataTable3 themeWithLocale=', themeWithLocale)

  // const classes = useStyles();

  const dynamicRows = rows

  return (
    <ThemeProvider theme={themeWithLocale}>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 880 }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead
              style={{ backgroundColor: 'red', color: 'white' }}
            >
              <TableRow>
                {columns.map(column => {
                  console.log('header column=', column)
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={
                        {
                          minWidth: column.minWidth,
                          color: 'black',
                          ...(column.sticky ? { position: "sticky", left: 0, zIndex: 999 } : {}),
                          backgroundColor: 'rgba(249, 193, 77, 1.0)',
                        }}
                    >
                      {column.label}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {dynamicRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={row.code}

                    >
                      {columns.map(column => {
                        const value = row[column.id]
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              color: 'black',
                              border: '1px dotted rgb(221, 221, 221)',
                              ...(column.sticky ? { position: "sticky", left: 0, zIndex: 999 } : {}),
                              ...(index % 2 == 0
                                ? {
                                  backgroundColor: 'rgba(255, 255, 255, 1.0)'
                                }
                                : {
                                  backgroundColor: 'rgba(255, 246, 224, 1.0)'
                                })
                            }}
                          >
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component='div'
          count={dynamicRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            [`& .${tablePaginationClasses.root}`]: {
              display: 'flex',
              alignItems: 'center'
            },
            [`& .${tablePaginationClasses.spacer}`]: {
              display: 'none'
            },
            [`& .${tablePaginationClasses.toolbar}`]: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              verticalAlign: 'middle',
              textAlign: 'center'
            },
            [`& .${tablePaginationClasses.selectLabel}`]: {
              verticalAlign: 'middle',
              //   fontSize: '1.5rem',
              textAlign: 'center',
              marginTop: '0',
              marginBottom: '0'
            },
            [`& .${tablePaginationClasses.selectIcon}`]: {
              color: 'black',
              paddingLeft: '2px'
            },
            [`& .${tablePaginationClasses.displayedRows}`]: {
              verticalAlign: 'middle',
              //   fontSize: '1.2rem',
              textAlign: 'right',
              marginTop: '0',
              marginBottom: '0'
            }
          }}
          style={{
            // backgroundColor: 'rgba(249, 193, 77, 1.0)',
            backgroundColor: 'Wheat',
            color: 'black'
          }}
          labelRowsPerPage='每頁數量：'
          labelDisplayedRows={({ from, to, count }) =>
            `第 ${from} ～ ${to} ／ ${count === -1 ? `超過 ${to}` : `共 ${count} 筆`}`
          }
        />
      </Paper>
    </ThemeProvider>
  )
}
