export default {
  color: `omegaDark`,
  textDecoration: `none`,
  textTransform: 'lowercase',
  userSelect: `none`,
  ':visited': {
    color: 'omegaDark'
  },
  ':hover': {
    color: 'alpha'
  },
}
