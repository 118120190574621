import * as React from 'react';
import { ThemeProvider, useThemeUI } from 'theme-ui'
import theme from '@elegantstack/flow-ui-theme/src/theme'
import {
  createTheme as materialCreateTheme,
  ThemeProvider as MaterialThemeProvider,
  useTheme as materialUseTheme,
  THEME_ID
} from '@mui/material/styles'
import Button from '@mui/material/Button';

function TestUseTheme() {
  console.log('useTheme=', useThemeUI())
  console.log('materialUseTheme=', materialUseTheme())
  const theme = materialUseTheme();
  return (
    <div>
      The Button is ugly because it is using {theme.palette.primary.main} as the
      color.
    </div>
  );
}

export default function ButtonUsage() {
  const materialTheme = materialCreateTheme()
  console.log('materialTheme=', materialTheme)
  // const theme = useTheme()
  // console.log('ButtonUsage theme=', theme)
  // return (<Button variant="contained" theme>Hello world</Button>);
  return (
    // <MaterialThemeProvider theme={{ [THEME_ID]: materialTheme }}>
    <ThemeProvider theme={materialTheme}>
      <TestUseTheme />
      <Button variant="contained">Hello world</Button>
    </ThemeProvider>
  );
}
