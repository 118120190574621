import normal from './normal'
import mute from './mute'
import vertical from './vertical'
import horizontal from './horizontal'

export default {
  normal,
  mute,
  vertical,
  horizontal,
  nav: {
    px: 2,
    py: 1,
    textTransform: 'lowercase',
    letterSpacing: '0.2em',
    display: `inline`,
  }
}
