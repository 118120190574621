import { merge } from 'theme-ui'
import typography from './typography'
import borderWidths from './borderWidths'
import breakpoints from './breakpoints'
import space from './space'
import sizes from './sizes'
import radii from './radii'
import colors from './colors'
import initialColorModeName from './initialColorModeName'
import prism from './prism'
import styles from './styles'
import buttons from './buttons'
import links from './links'
import text from './text'
import cards from './cards'
import lists from './lists'
import badges from './badges'
import messages from './messages'
import forms from './forms'
import layout from './layout'
import global from './global'


// import future from '@theme-ui/preset-base'


// const theme = {
//   ...baseTheme,
//   colors: {
//     ...baseTheme.colors,
//     // text: '#111',
//     // background: '#fff',
//   },
// }

// const presetTheme = merge(future, typography)

// Michael Lu, 2024/07/06
// import { ThemeUIProvider } from 'theme-ui'
// import {
//   createTheme as materialCreateTheme,
//   // ThemeProvider as MaterialThemeProvider,
//   // THEME_ID
// } from '@mui/material/styles'

// const themeUITheme = {
//   fonts: {
//     body: 'system-ui, sans-serif',
//     heading: '"Avenir Next", sans-serif',
//     monospace: 'Menlo, monospace'
//   },
//   colors: {
//     text: '#000',
//     background: '#fff',
//     primary: '#33e'
//   }
// }

// const materialTheme = materialCreateTheme()
// console.log('merged materialTheme=', materialTheme)

const presetTheme = merge({}, typography)
// const presetTheme = merge(materialTheme, typography)
const theme = merge(presetTheme, {
  config: { 
    useCustomProperties: true,
    initialColorModeName,
  },
  useCustomProperties: true,
  initialColorModeName,

  colors,
  prism,
  breakpoints,
  sizes,
  space,
  radii,
  borderWidths,
  styles,
  buttons,
  links,
  text,
  cards,
  lists,
  badges,
  messages,
  forms,
  layout,
  global
})

// console.log('theme=', theme)

export default theme
